<template>
    <PopupReasignacionPrincipal>
        <div class="header-content2">
            <div class="close-style2">
                <img @click="closePopup" style="cursor: pointer; margin-top: -1rem" width="25" height="25"
                    src="@/assets/eliminar-50.png" alt="delete-sign--v1" />
            </div>


            <div>
                <h1 style="
              text-align: center;
              margin-top: 1rem;
              margin-bottom: 3rem;
              color: white;
              font-weight: 100;
            ">
                    {{ $t("message.planta.op14") }}
                </h1>
            </div>
        </div>

        <div class="group">
            <h3 style="color: white; line-height: 25px">
                {{ terminalSelected.terminals_nickname.toUpperCase() }}
            </h3>
        </div>

        <div class="texto-alerta">
            <p style="
            color: #ffff;
            line-height: 23px;
            text-align: justify;
            font-size: 1.4rem;
          ">
                {{ $t("message.planta.b11") }}
            </p>
        </div>

        <div>
            <form class="popup-form">
                <div class="group">
                    <v-select v-model="principalId" :options="principal" label="name" required
                        :placeholder="$t('message.planta.f31')" class="custom-select" />
                </div>
            </form>
        </div>

        <br>
        <br>
        <div class="btn-content2" style="display: flex; justify-content: space-evenly">
            <button class="btn-baja" @click="desbloquearEquipo" type="submit" id="btn-cerrar-popup2">
                <strong> {{ $t("message.planta.f43") }}</strong>
            </button>
            <!-- <button class="btn-baja" @click="closePopup" type="button">
                <strong>Close</strong>
            </button> -->
        </div>
    </PopupReasignacionPrincipal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import store from "@/store/index";
import "@/assets/sass/StylesPlanta.scss";
import { mapState, mapGetters } from "vuex";
import PopupReasignacionPrincipal from "@/components/planta-conte/PopupReasignacionPrincipal.vue";
import vSelect from "vue-select";



export default defineComponent({
    name: "newService",
    props: ["terminalSelected"],
    emits: ["close", "confirm"],
    components: {
        PopupReasignacionPrincipal,
        vSelect
    },
    computed: {
        ...mapState({
            user: (state: any) => state.AUTH.user,
            token: (state: any) => state.AUTH.token,
        }),
        ...mapGetters(["permissions"]),
    },
    data() {
        return {
            desbloquearReason: "",
            messageTraduct: "",
            principalId: null,
            principal: [],
        };
    },
    mounted() {
        var data = {
            endpoint: 13,
            type: "data"
        };
        this.serviceline(data);
    },
    methods: {
        closePopup() {
            this.$emit("close");
        },

        desbloquearEquipo() {

            let data = {
                serviceLineNumber: (this.terminalSelected as any).serviceLineNumber,
                principalId: (this.principalId as any).principalId
            }

            store
                .dispatch("REASSIGNMENTPRINCIPAL", data)
                .then((response) => {
                    console.log(response);
                    this.closePopup()

                    store.dispatch("showToast", {
                        message: "Se Cambio el Principal de los Devices " ,
                        type: "success",
                    });
                })
                .catch((error) => {
                    console.log("error", error);
                });
        },


        serviceline(data: any) {
            store
                .dispatch("SERVICELINEUSERCONFIG", data)
                .then((response) => {
                    console.log(response);
                    this.principal = response.data.data
                })
                .catch((error) => {
                    console.log("error", error);
                });
        },

    },
});
</script>
<style lang="scss">
.dropdown-content {
    position: fixed;
    display: none;
    width: 160px;
    height: 225px;
    background: #0066ff;
    border-radius: 10px;
    margin-top: 0px;
}

.dropdown-content:last-of-type {
    position: fixed;
    display: none;
    width: 160px;
    height: 225px;
    background: #0066ff;
    border-radius: 10px;
    margin-top: -225px;
    margin-left: 45px;
}

.dropdown-content:hover {
    position: fixed;
    display: none;
    width: 160px;
    height: 225px;
    background: #0066ff;
    border-radius: 10px;
}

.dropdown-content a:hover {
    background-color: #0079ff;
}

.btn-submit-address {
    height: 32px;
    width: 220px;
    border: none;
    border-radius: 9px;
    color: #ffffff;
    font-weight: bold;
    background-color: #0066ff;
}

.btn-submit-mapa {
    height: 32px;
    width: 220px;
    border: none;
    border-radius: 9px;
    color: #ffffff;
    font-weight: bold;
    background-color: #0066ff;
}

.custom-select .vs__dropdown-toggle {
    background-color: white;
}

.custom-input {
    background-color: white;
    border: 1px solid #ccc;
    padding: 8px;
    border-radius: 4px;
    width: 95%;
    cursor: pointer;
}

.header-content {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
}

.poppup-body p {
    color: #ffffff;
}

.btn-content2 button {
    height: 40px;
    cursor: pointer;
    width: 9rem;
    border-radius: 9px;
    background-color: #007bff;
    color: #ffffff;
}

.close-style2 {
    display: flex;
    justify-content: end;
}
</style>