import request from "@/utils/flask";
const obj = {
  insertFile(data: any) {
    return request({
      url: "/api/insert-upload-file",
      method: "post",
      data: data,
    });
  },

  excelTerminal(data: any) {
    return request({
      url: "/api/excelTerminal",
      method: "post",
      data: data,
    });
  },

  updateNickname(data: any) {
    return request({
      url: "/api/update-nickname",
      method: "post",
      data: data,
    });
  },

  addDevice(data: any) {
    return request({
      url: "/api/add-device",
      method: "post",
      data: data,
    });
  },

  deleteDevice(data: any) {
    return request({
      url: "/api/delete-device",
      method: "post",
      data: data,
    });
  },
  addKit(data: any) {
    return request({
      url: "/api/add-kits",
      method: "post",
      data: data,
    });
  },

  swapKit(data: any) {
    return request({
      url: "/api/swap-kits",
      method: "post",
      data: data,
    });
  },
  

};

export default obj;
