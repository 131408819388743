import request from "@/utils/starlinkv2";
const obj = {
  
  updateNickname(data: any) {
    return request({
      url: "/api/update-nickname",
      method: "post",
      data: data,
    });
  },

  addDevice(data: any) {
    return request({
      url: "/api/add-device",
      method: "post",
      data: data,
    });
  },

  deleteDevice(data: any) {
    return request({
      url: "/api/delete-device",
      method: "post",
      data: data,
    });
  },
  addKit(data: any) {
    return request({
      url: "/api/add-kits",
      method: "post",
      data: data,
    });
  },

  swapKit(data: any) {
    return request({
      url: "/api/swap-kits",
      method: "post",
      data: data,
    });
  },

  reassignmentPrincipal(data: any) {
    return request({
      url: "/api/update_device_principal",
      method: "post",
      data: data,
    });
  },

  optIn(data: any) {
    return request({
      url: "/api/opt-in",
      method: "post",
      data: data,
    });
  },

  optOut(data: any) {
    return request({
      url: "/api/opt-out",
      method: "post",
      data: data,
    });
  },
};

export default obj;
