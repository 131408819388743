import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import primeVue from 'primevue/config';
import { createI18n } from 'vue-i18n';
import { getLocale } from "@/utils/auth";
import { KeycloakPlugin } from "./plugins/keycloak";
import 'primevue/resources/themes/lara-light-blue/theme.css'; // Cambia a un tema oscuro
import 'primevue/resources/primevue.min.css';
// import 'primeicons/primeicons.css';
const messages = {
  en: {
    message: {
      language: 'en',
      export: 'Export',
      title: 'Leo Services',
      auth: {
        logout: "Log out",
        login: "Login",
        f1: "Username",
        f2: "Password",
        f3: "Sign In",
        f4: "edit profile",
        f5: "support",
        f6: "Forgot Password?",
        f7: "Reset password",
        f8: "Go back"
      },
      home: {
        btn1: "Device List",
        btn2: "New Service",
        btn3: "Monitoring",
        btn4: "Unlock Starlink Equipment",
        btn5: "Add Starlink Equipment",
        m01:"Unlock Starlink devices from your account. To be unlocked, Starlink devices must NOT be linked to a line of service.",
        m02:"Add new Starlink devices to your account. These Starlinks must be unlocked by the owner, and will not be activated until you have added them to a service."
      },
      planta: {
        search: "Search",
        c1: "Customer",
        c2: "Site ID",
        c3: "Suscriptor Name",
        c4: "Comercial Status",
        c5: "Technical Status",
        c6: "Serial Number",
        c7: "Last connection",
        c8: "Daily Use (L.C.)",
        c9: "Cycle use GB",
        c10: "Service Plan",
        c11: "Address",
        c12: "Long",
        c13: "Lat",
        c14: "Options",
        op1: "Commissioning",
        op2: "Update Service Plan",
        op3: "Update Address",
        op4: "Suspend Service",
        op5: "Cancel Service",
        op6: "Support",
        op7: "Monitoring",
        op8: "Change SiteID",
        op9: "Reactivate Service",
        op10: "Change Bypass",
        op11: "Reset Remote",
        op12: "Add KIT to service",
        op13: "Change KIT",
        op14: "Reassignment of principal",
        op15: "Suspend service by schedule",
        op16: "Custom DNS",
        op17: "Rename wifi network",
        op18: "Change wifi password",
        op19: "Change password",
        op20: "Reset",
        op21: "Suspend",
        op22: "Change DNS",
        op23: "Rename",
        op24: "Activate",
        op25: "Deactivate",
        op26: "Data will continue to be charged for a maximum of two hours.",
        op27: "Additional prioritized data",
        op28: "Activate additional priority data",
        op29: "Desactivate additional priority data",
        f1: "New Service",
        f2: "Address",
        f3: "Verify Address",
        f4: "Update Address",
        f5: "Site ID",
        f6: "select service plan",
        f7: "select serial number",
        f8: "New Service",
        f9: "Commissioning",
        f10: "Select File",
        f11: "Upload File",
        f12: "Update Service Plan",
        f13: "Select service plan",
        f14: "Update Service Plan",
        f15: "Long",
        f16: "Lat",
        f17: "New Address",
        f18: "Update Address",
        f19: "Cancel Service",
        f20: "Do you really want to cancel the service git",
        f21: "Cancel Service",
        f22: "Support",
        f23: "Contact Name",
        f24: "Contact e-mail",
        f25: "select Problem",
        f26: "Description",
        f27: "Send Ticket",
        f28: "Suspend Service",
        f29: "Do you really want to suspend the service ",
        f30: "Suspend Service",
        f31: "select a principal",
        f32: "confirm",
        f33: "Enter the new siteID name",
        f34: "This action will change the SiteID name",
        f35: "Enter detail ( optional )",
        f36: "Enter the country with which the kit will be registered.",
        f37: "NOTE: You can enter a single ID or a comma-separated list.",
        f38: "Add",
        f39: "Unlock",
        f40: "Add KIT",
        f41: "After confirming the reactivation of the service, wait 20 minutes to use the navigation data.",
        f42: "Reactivate",
        f43: "Change Principal",
        f44: "Switch to Bypass mode",
        b01: "Deregistration carried out successfully",
        b02: "It is no longer listed on the plant list",
        b03: "Released and available to assign to another service",
        b04: "will continue to be billed until the next day 5",
        b05: "Service",
        b06: "The service",
        b07: "Remotely bypassing your wifi router is a one-sectional operation. To disable bypassing the wifi router, a user with physical access must reset it to factory settings. Bypass mode disables all networks on the wifi router.",
        b08: "Are you sure you want to restart your Starlink? This will temporarily interrupt the service",
        b09: "This action will add KIT to the existing service",
        b10: "The service is currently assigned the kit : ",
        b11: "This action will change the assignment of principal to the KIT.",
        b12: "You will not be able to use browsing data during the period of suspension.",
        b13: "Choose the public DNS server of your choice.",
        b14: "Enter new SSID.",
        b15: "Enter new wifi network password.",
        b16: "Activate this option if you want to keep the network priority after exceeding the data included in your plan. Data is automatically charged per GB until you disable this feature. This feature will start within two hours.",
        m01: "MONTHLY CONSUMPTION: ",
        m02: "Active",
        m03: "Inactive",
        m04: "Select Contact Type",
        m05: "Enter Customer",
        m06: "Select Media Communication",
        m07: "Select the request type",
        m08: "NEW KIT SERIAL NUMBER"

      },
      profile: {
        pr01: "Welcome",
        pr02: "User",
        pr03: "Full Name",
        pr04: "Password",
        pr05: "Confirm Password"
      }

    }
  },
  es: {
    message: {
      language: 'es',
      export: 'Exportar',
      title: 'Servicios LEO',
      auth: {
        logout: "salir",
        login: "Iniciar sesión",
        f1: "Usuario",
        f2: "Contraseña",
        f3: "Ingresar",
        f4: "editar perfil",
        f5: "soporte",
        f6: "¿Has olvidado tu contraseña?",
        f7: "resetear contraseña",
        f8: "volver"
      },
      home: {
        btn1: "Planta",
        btn2: "Nuevo Servicio",
        btn3: "Monitoreo",
        btn4: "Desbloquear Equipo Starlink",
        btn5: "Agregar Equipo Starlink",
        m01: "Desbloquee los equipos Starlink de su cuenta. Para desbloquearlos, los equipos Starlink NO deben estar vinculados a una línea de servicio.",
        m02:"Agregue nuevos equipos Starlink a su cuenta. El propietario debe desbloquear estos equipos Starlinks, y no se activarán hasta que los haya agregado a un servicio."
      },
      planta: {
        search: "Ingrese para realizar la busqueda…",
        c1: "Cliente",
        c2: "Site Id",
        c3: "Nombre Suscritor",
        c4: "Estado comercial",
        c5: "Estado Técnico",
        c6: "Numero de Serie",
        c7: "última conexión",
        c8: "consumo diario (U.C.)",
        c9: "Consumo de ciclo GB",
        c10: "Plan de Servicio",
        c11: "Dirección",
        c12: "Longitud",
        c13: "Latitud",
        c14: "Acción",
        op1: "Comisionamiento",
        op2: "Cambio de plan",
        op3: "Cambio de Dirección",
        op4: "Suspender",
        op5: "Baja Total",
        op6: "Soporte",
        op7: "Tráfico y consumo",
        op8: "Cambiar SiteID",
        op9: "Reactivar Servicio",
        op10: "Cambiar Bypass",
        op11: "Reinicio Remoto",
        op12: "Agregar KIT al servicio",
        op13: "Cambiar KIT",
        op14: "Reasignación de principal",
        op15: "Suspender servicio por horario",
        op16: "DNS personalizado",
        op17: "Renombrar red wifi",
        op18: "Cambiar contraseña wifi",
        op19: "Cambiar contraseña",
        op20: "Reinicio",
        op21: "Suspender",
        op22: "Cambiar DNS",
        op23: "Renombrar",
        op24: "Activar",
        op25: "Desactivar",
        op26: "Los datos se seguirán cobrando por un máximo de dos horas.",
        op27: "Datos priorizados adicionales",
        op28: "Activar datos priority adicionales",
        op29: "Desactivar datos priority adicionales",
        f1: "Nuevo Servicio",
        f2: "Dirección",
        f3: "Verificar Dirección",
        f4: "Cambiar Dirección",
        f5: "Site Id",
        f6: "Seleccionar Plan de Servicio",
        f7: "Seleccionar Serial Number",
        f8: "Nuevo Servicio",
        f9: "Comisionamiento",
        f10: "Elegir archivo",
        f11: "Cargar Archivo",
        f12: "Cambio de plan",
        f13: "Seleccionar plan de servicio",
        f14: "Cambio de plan",
        f15: "Longitud",
        f16: "Latitud",
        f17: "Nueva Dirección",
        f18: "Cambiar Dirección",
        f19: "Baja Total",
        f20: "¿REALMENTE DESEA DAR DE BAJA TOTAL SU SERVICIO",
        f21: "Baja Total",
        f22: "Soporte",
        f23: "Nombre de contacto",
        f24: "Correo de contacto",
        f25: "Tipo de Problema",
        f26: "Descripción",
        f27: "Generar Ticket",
        f28: "SUSPENDER",
        f29: "¿REALMENTE DESEA SUSPENDER SU SERVICIO ",
        f30: "SUSPENDER",
        f31: "selecciona un principal",
        f32: "confirmar",
        f33: "Ingresar el nuevo nombre del SiteID",
        f34: "Esta acción cambiará el nombre del SiteID",
        f35: "Ingresar detalle ( opcional )",
        f36: "Ingresar el país con el que se registrará el kit.",
        f37: "NOTA: Puede ingresar un único ID o una lista separada por comas.",
        f38: "Agregar",
        f39: "Desbloquear",
        f40: "Agregar KIT",
        f41: "Tras confirmar la reactivación del servicio, espere 20minutos para hacer uso de los datos de navegación.",
        f42: "Reactivar",
        f43: "Cambiar Principal",
        f44: "Cambiar a modo Bypass",
        b01: "Baja realizada de manera exitosa",
        b02: "Ya no figura en el listado de planta",
        b03: "Liberado y disponible para asignar a otro servicio",
        b04: "se seguira facturando hasta el próximo día 5",
        b05: "Servicio",
        b06: "El servicio",
        b07: "Omitir su router de wifi de manera remota es una operación unidereccional. Para desactivar la omision del router de wifi, un usuario con acceso físico debe restablecerlo a la configuración de fábrica. Elmodo de derivación desactiva todas las redes el router wifi.",
        b08: "¿Esta seguro de que desea reiniciar su Starlink? Esto interrumpirá el servicio temporalmente.",
        b09: "Esta acción agregara KIT al servicio existente.",
        b10: "El servicio actualmente tiene asignado el kit : ",
        b11: "Esta acción realizará el cambio de asignación de principal al KIT.",
        b12: "No podrá hacer uso de datos de navegación en el periodo que dure la suspensión.",
        b13: "Elige el servidor DNS público que quieras.",
        b14: "Ingresar nuevo SSID.",
        b15: "Ingresar nuevo contraseña de la red wifi.",
        b16: "Active esta opción si desea mantener la prioridad de la red después de superar los datos incluidos en su plan. Los datos se cobran de forma automática por GB hasta que desactive esta función. Esta función comenzará en un plazo de dos horas.",
        m01: "CONSUMO DEL MES: ",
        m02: "Activo",
        m03: "Inactivo",
        m04: "Selecciona tipo de contacto",
        m05: "Ingresa el Cliente",
        m06: "Selecciona la Media Comunicacion",
        m07: "Selecciona el Tipo de solicitud",
        m08: "NUEVO KIT SERIAL NUMBER",
      },
      profile: {
        pr01: "Bienvenido",
        pr02: "Usuario",
        pr03: "Nombre Completo",
        pr04: "Contraseña",
        pr05: "Confirmar Contraseña"
      }
    }
  },
  pt: {
    message: {
      language: 'pt',
      export: 'Exportar',
      title: 'Serviços LEO',
      auth: {
        logout: "Sair",
        login: "Iniciar sessão",
        f1: "Usuário",
        f2: "Senha",
        f3: "Entrar",
        f4: "editar perfil",
        f5: "suporte",
        f6: "Esqueceu sua senha?",
        f7: "redefinir senha",
        f8: "volte"
      },
      home: {
        btn1: "Lista de Dispositivos",
        btn2: "Novos Serviços",
        btn3: "Monitoramento",
        btn4: "Desbloquear a equipe Starlink",
        btn5: "Adicionar  a equipe Starlink",
        m01: "Desbloqueie os dispositivos Starlink de sua conta. Para serem desbloqueados, os dispositivos Starlink NÃO devem estar vinculados a uma linha de serviço.",
        m02:"Adicione novos dispositivos Starlink à sua conta. Esses Starlinks devem ser desbloqueados pelo proprietário e não serão ativados até que você os adicione a um serviço."
      },
      planta: {
        search: "Buscar…",
        c1: "Cliente",
        c2: "Site ID",
        c3: "Nome do Cliente",
        c4: "Status Comercial",
        c5: "Status Técnico",
        c6: "Número de Série",
        c7: "Última Conexão",
        c8: "Consumo Diário (UC)",
        c9: "Consumo do Ciclo GB",
        c10: "Plano contratado",
        c11: "Endereço",
        c12: "Longitude",
        c13: "Latitude",
        c14: "Ações",
        op1: "Comissionamento",
        op2: "Atualização do Plano contratado ",
        op3: "Atualização Endereço",
        op4: "Suspender Serviço",
        op5: "Cancelar Serviço",
        op6: "Suporte",
        op7: "Monitoramento",
        op8: "Cambio SiteID",
        op9: "Reactivacao",
        op10: "Alterar Bypass",
        op11: "Reinicialização remota",
        op12: "Adicionar KIT ao serviço",
        op13: "Mudar o KIT",
        op14: "Realocação do principal",
        op15: "Suspender o serviço por programação",
        op16: "DNS personalizado",
        op17: "Renomear a rede wifi",
        op18: "Alterar a senha do wifi",
        op19: "Alterar a senha",
        op20: "Reinicialização",
        op21: "Suspensão",
        op22: "Alterar DNS",
        op23: "Renomear",
        op24: "Ativação",
        op25: "Desativar",
        op26: "Os dados continuarão a ser cobrados por um período máximo de duas horas.",
        op27: "Dados adicionais priorizados",
        op28: "Ativar dados prioritários adicionais",
        op29: "Desactivate dados prioritários adicionais",
        f1: "NOVO SERVIÇO",
        f2: "Endereço",
        f3: "Verificar o Endereço",
        f4: "Atualização Endereço",
        f5: "Site ID",
        f6: "Selecionar Plano contratado",
        f7: "Selecionar Número de Série",
        f8: "Novo Serviço",
        f9: "COMISSIONAMENTO",
        f10: "Selecionar Arquivo",
        f11: "Carregar Arquivo",
        f12: "ATUALIZAR PLANO DE SERVIÇO",
        f13: "Selecionar Arquivo",
        f14: "Atualização do Plano contratado ",
        f15: "Longitud",
        f16: "Latitud",
        f17: "Novo Endereço",
        f18: "Atualização Endereço",
        f19: "CANCELAR SERVIÇO",
        f20: "Cancelar o serviço ",
        f21: "Cancelar Serviço",
        f22: "APOIAR",
        f23: "Nome de Contato",
        f24: "Email de Contato",
        f25: "Selecione o Problema",
        f26: "Descrição",
        f27: "Enviar Ticket",
        f28: "SUSPENDER SERVIÇO",
        f29: "Deseja suspender o serviço ",
        f30: "Suspender Serviço",
        f31: "selecione um principal",
        f32: "confirme",
        f33: "Introduzir o novo nome do SiteID",
        f34: "Essa ação alterará o nome do SiteID",
        f35: "Insira detalhes ( opcional )",
        f36: "Digite o país no qual o kit deve ser registrado.",
        f37: "OBSERVAÇÃO: você pode inserir um único ID ou uma lista separada por vírgulas.",
        f38: "Adicionar",
        f39: "Desbloquear",
        f40: "Adicionar KIT",
        f41: "Após confirmar a reativação do serviço, aguarde 20 minutos para usar os dados de navegação.",
        f42: "Reativar",
        f43: "Modificar principal",
        f44: "Alternar para o modo Bypass",
        b01: "Cancelamento de registro realizado com sucesso",
        b02: "Não está mais listado na lista de plantas",
        b03: "Liberado e disponível para atribuir a outro serviço",
        b04: "continuará a ser cobrado até o próximo dia 5",
        b05: "Serviço",
        b06: "O serviço",
        b07: "Ignorar remotamente o roteador Wi-Fi é uma operação de uma seção. Para desativar o desvio do roteador Wi-Fi, um usuário com acesso físico deve redefini-lo para as configurações de fábrica. O modo de desvio desativa todas as redes no roteador Wi-Fi.",
        b08: "Tem certeza de que deseja reiniciar o Starlink? Isso interromperá temporariamente o serviço.",
        b09: "Essa ação adicionará o KIT ao serviço existente.",
        b10: "Atualmente, o kit é atribuído ao serviço : ",
        b11: "Essa ação executará a alteração da atribuição principal para o KIT.",
        b12: "Você não poderá usar os dados de navegação durante o período da suspensão.",
        b13: "Escolha o servidor DNS público de sua preferência.",
        b14: "Digite o novo SSID.",
        b15: "Digite a nova senha da rede wifi.",
        b16: "Ative essa opção se quiser manter a prioridade da rede depois de exceder os dados incluídos em seu plano. Os dados são cobrados automaticamente por GB até que você desative essa função. Esse recurso será iniciado em duas horas.",
        m01: "CONSUMO DO MÊS: ",
        m02: "Ativo",
        m03: "Inativo",
        m04: "Selecione o tipo de contato",
        m05: "Login do cliente",
        m06: "Selecione Comunicação de Mídia",
        m07: "Selecione o tipo de aplicativo",
        m08: "NOVO KIT SERIAL NUMBER",
      },
      profile: {
        pr01: "Bem-vindo",
        pr02: "Usuário",
        pr03: "Nome completo",
        pr04: "Senha",
        pr05: "Confirmar senha"
      }
    }
  }
}
const i18n = createI18n({
  locale: getLocale() ? getLocale() : 'en',
  fallbackLocale: 'en',
  warnHtmlInMessage: "off",
  messages
})
createApp(App).use(store).use(router).use(primeVue).use(KeycloakPlugin).use(i18n).mount('#app')
