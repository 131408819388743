<template>
    <div class="popup">
        <div class="popup-content">
            <slot></slot>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "Popup",
});
</script>

<style scoped>
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(8, 8, 8, 0.933);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-content {
    background-color: white;
    padding: 60px;
    /* Incrementamos el padding para más espacio */
    width: 150%;
    /* Aumentamos el ancho un 50% más */
    max-width: 33rem;
    /* Ajustamos el máximo ancho según necesites */
    max-height: 50rem;
    background-image: url("../../assets/Rectangle\ 40\ popup.png");
    /* Ruta de la imagen de fondo */
    background-size: cover;
    opacity: 1;
    /* Opacidad de la imagen de fondo */
    border-radius: 10px;
}
</style>