import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/eliminar-50.png'


const _hoisted_1 = { class: "header-content2" }
const _hoisted_2 = { class: "close-style2" }
const _hoisted_3 = { class: "texto-alerta" }
const _hoisted_4 = { style: {"color":"#ffff","line-height":"23px","text-align":"justify","font-size":"1.4rem"} }
const _hoisted_5 = {
  class: "btn-content2",
  style: {"display":"flex","justify-content":"space-evenly"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PopupDesactivarDatosPrioritarios = _resolveComponent("PopupDesactivarDatosPrioritarios")!

  return (_openBlock(), _createBlock(_component_PopupDesactivarDatosPrioritarios, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closePopup && _ctx.closePopup(...args))),
            style: {"cursor":"pointer","margin-top":"-1rem"},
            width: "25",
            height: "25",
            src: _imports_0,
            alt: "delete-sign--v1"
          })
        ]),
        _cache[2] || (_cache[2] = _createElementVNode("div", null, [
          _createElementVNode("h1", { style: {"text-align":"center","margin-top":"1rem","margin-bottom":"3rem","color":"white","font-weight":"100"} }, " Desactivar Datos Prio ")
        ], -1))
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("message.planta.op26")), 1)
      ]),
      _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
      _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("button", {
          class: "btn-baja",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.cambiarKit && _ctx.cambiarKit(...args))),
          type: "submit",
          id: "btn-cerrar-popup2"
        }, [
          _createElementVNode("strong", null, _toDisplayString(_ctx.$t("message.planta.op25")), 1)
        ])
      ])
    ]),
    _: 1
  }))
}