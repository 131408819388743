<template>
    <PopupActivarDatosPrioritarios>
        <div class="header-content2">
            <div class="close-style2">
                <img @click="closePopup" style="cursor: pointer; margin-top: -1rem" width="25" height="25"
                    src="@/assets/eliminar-50.png" alt="delete-sign--v1" />
            </div>


            <div>
                <h1 style="
              text-align: center;
              margin-top: 1rem;
              margin-bottom: 3rem;
              color: white;
              font-weight: 100;
            ">
                    {{ $t("message.planta.op27") }}
                </h1>
            </div>
        </div>


        <div class="texto-alerta">
            <p style="
            color: #ffff;
            line-height: 23px;
            text-align: justify;
            font-size: 1.4rem;
          ">
          {{ $t("message.planta.b16") }}
            </p>
        </div>

        

        <br>
        <br>

        <div class="btn-content2" style="display: flex; justify-content: space-evenly">
            <button class="btn-baja" @click="cambiarKit" type="submit" id="btn-cerrar-popup2">
                <strong>{{ $t("message.planta.op24") }}</strong>
            </button>

        </div>
    </PopupActivarDatosPrioritarios>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import store from "../../../store";
import "@/assets/sass/StylesPlanta.scss";
import { mapState, mapGetters } from "vuex";
import PopupActivarDatosPrioritarios from "@/components/planta-conte/PopupActivarDatosPrioritarios.vue";


export default defineComponent({
    name: "newService",
    props: ["terminalSelected"],
    emits: ["close"],
    components: {
        PopupActivarDatosPrioritarios,
    },
    computed: {
        ...mapState({
            user: (state: any) => state.AUTH.user,
            token: (state: any) => state.AUTH.token,
        }),
        ...mapGetters(["permissions"]),
    },
    data() {
        return {
            kitNumber: "",
            messageTraduct: ""
        };
    },
    mounted() { },
    methods: {
        closePopup() {
            this.$emit("close");
        },

        cambiarKit() {
            let data = {
                accountNumber: (this.terminalSelected as any).accountNumber,
                serviceLineNumber: (this.terminalSelected as any).serviceLineNumber
            }


            store
                .dispatch("OPTIN", data)
                .then((response) => {
                    console.log(response);
                    this.closePopup()

                    store.dispatch("showToast", {
                        message: "Se Activo los Datos Prioritario" ,
                        type: "success",
                    });
                })
                .catch((error) => {
                    console.log("error", error);
                });
        }




    },
});
</script>
<style lang="scss">
.dropdown-content {
    position: fixed;
    display: none;
    width: 160px;
    height: 225px;
    background: #0066ff;
    border-radius: 10px;
    margin-top: 0px;
}

.dropdown-content:last-of-type {
    position: fixed;
    display: none;
    width: 160px;
    height: 225px;
    background: #0066ff;
    border-radius: 10px;
    margin-top: -225px;
    margin-left: 45px;
}

.dropdown-content:hover {
    position: fixed;
    display: none;
    width: 160px;
    height: 225px;
    background: #0066ff;
    border-radius: 10px;
}

.dropdown-content a:hover {
    background-color: #0079ff;
}

.btn-submit-address {
    height: 32px;
    width: 220px;
    border: none;
    border-radius: 9px;
    color: #ffffff;
    font-weight: bold;
    background-color: #0066ff;
}

.btn-submit-mapa {
    height: 32px;
    width: 220px;
    border: none;
    border-radius: 9px;
    color: #ffffff;
    font-weight: bold;
    background-color: #0066ff;
}

.custom-select .vs__dropdown-toggle {
    background-color: white;
}

.custom-input {
    background-color: white;
    border: 1px solid #ccc;
    padding: 8px;
    border-radius: 4px;
    width: 95%;
    cursor: pointer;
}

.header-content {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
}

.poppup-body p {
    color: #ffffff;
}

.btn-content2 button {
    height: 40px;
    cursor: pointer;
    width: 9rem;
    border-radius: 9px;
    background-color: #007bff;
    color: #ffffff;
}

.close-style2 {
    display: flex;
    justify-content: end;
}
</style>