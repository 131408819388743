<template>
    <PopupSuspenderHorario>
        <div class="header-content2">
            <div class="close-style2">
                <img @click="closePopup" style="cursor: pointer; margin-top: -1rem" width="25" height="25"
                    src="@/assets/eliminar-50.png" alt="delete-sign--v1" />
            </div>

            <div>
                <h1 style="text-align: center; margin-top: 1rem; margin-bottom: 3rem; color: white; font-weight: 100;">
                    {{ $t("message.planta.op15") }}
                </h1>
            </div>
        </div>

        <div class="group">
            <h3 style="color: white; line-height: 25px">
                {{ terminalSelected.terminals_nickname.toUpperCase() }}
            </h3>
        </div>

        <div class="texto-alerta">
            <p style="color: #ffff; line-height: 23px; text-align: justify; font-size: 1.4rem;">
                {{ $t("message.planta.b12") }}
            </p>
        </div>

        <div class="card flex flex-wrap gap-3 p-fluid">
            <div class="flex-auto">
                <!-- <label for="calendar-12h" class="font-bold block mb-2"> 12h Format </label> -->
                <Calendar class="font-bold " id="calendar-12h" v-model="datetime12h" showTime hourFormat="12"
                    selectionMode="range" :manualInput="false" />
            </div>
        </div>

        <br><br>
        <div class="btn-content2" style="display: flex; justify-content: space-evenly">
            <button class="btn-baja" @click="desbloquearEquipo" type="submit" id="btn-cerrar-popup2">
                <strong>{{ $t("message.planta.op21") }}</strong>
            </button>
        </div>
    </PopupSuspenderHorario>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import store from "../../../store";
import "@/assets/sass/StylesPlanta.scss";
import { mapState, mapGetters } from "vuex";
import vSelect from "vue-select";
import Calendar from 'primevue/calendar';
import PopupSuspenderHorario from "@/components/planta-conte/PopupSuspenderHorario.vue";

export default defineComponent({
    name: "newService",
    props: ["terminalSelected"],
    emits: ["close", "confirm"],
    components: {
        PopupSuspenderHorario,
        vSelect,
        Calendar
    },
    computed: {
        ...mapState({
            user: (state: any) => state.AUTH.user,
            token: (state: any) => state.AUTH.token,
        }),
        ...mapGetters(["permissions"]),
    },
    data() {
        return {
            desbloquearReason: "",
            messageTraduct: "",
            principalId: null,
            principal: [],
            datetime12h: null,
        };
    },
    methods: {
        closePopup() {
            this.$emit("close");
        },
        desbloquearEquipo() {
            // Función de desbloqueo, por implementar
        },
        accounNumber() {
            const data = {
                endpoint: 31,
                type: "data",
            };
            store
                .dispatch("SERVICELINEUSERCONFIG", data)
                .then((response) => {
                    console.log(response);
                })
                .catch((error) => {
                    console.log("error", error);
                });
        },
        confirmPopupPrincipalTest() {
            console.log("test", this.principalId);
            this.$emit("confirm");
            store.commit("SET_PRINCIPAL_SELECTED", this.principalId);
        },
        serviceline(data: any) {
            console.log(data);
            store
                .dispatch("SERVICELINEUSERCONFIG", data)
                .then((response) => {
                    console.log(response);
                    this.principal = response.data.data;
                    this.accounNumber();
                })
                .catch((error) => {
                    console.log("error", error);
                });
        },
    },
});
</script>

<style lang="scss">
/* Tus estilos existentes */
span {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 550;
    font-size: 11px;
    line-height: 25px;
    color: #374151;
}

/* Botón */
.btn-content2 {
    display: flex;
    justify-content: space-evenly;
    margin-top: 1rem;
}

.btn-baja {
    height: 40px;
    cursor: pointer;
    width: 9rem;
    border-radius: 9px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
}
</style>
