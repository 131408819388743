<template>
    <PopupDesbloquearEquipo>
        <div class="header-content2">
            <div class="close-style2">
                <img @click="closePopup" style="cursor: pointer; margin-top: -1rem" width="25" height="25"
                    src="@/assets/eliminar-50.png" alt="delete-sign--v1" />
            </div>


            <div>
                <h1 style="
              text-align: center;
              margin-top: 1rem;
              margin-bottom: 3rem;
              color: white;
              font-weight: 100;
            ">
                    {{ $t("message.home.btn4") }}
                </h1>
            </div>
        </div>

        <div class="group">
            <h3 style="color: white; line-height: 25px">
                <!-- {{ terminalSelected.terminals_nickname.toUpperCase() }} -->
            </h3>
        </div>

        <div class="texto-alerta">
            <p style="
            color: #ffff;
            line-height: 23px;
            text-align: justify;
            font-size: 1.4rem;
          ">
                {{ $t("message.home.m01") }}
            </p>
        </div>

        <div class="reason-input">
            <input type="text" id="reason" v-model="desbloquearReason" placeholder="KIT SERIAL NUMBER"
                style="width: 100%; padding: 0.5rem; margin-top: 0.5rem;" />
            <br>
            <br>
            <label for="reason" style="color: white;">
                {{ $t("message.planta.f37") }}
                <!-- NOTA: Puede ingresar un único ID o una lista separada por comas. -->
            </label>
        </div>

        <br>
        <br>

        <div class="btn-content2" style="display: flex; justify-content: space-evenly">
            <button class="btn-baja" @click="desbloquearEquipo" type="submit" id="btn-cerrar-popup2">
                <strong>{{ $t("message.planta.f39") }}</strong>
            </button>
            <!-- <button class="btn-baja" @click="closePopup" type="button">
                <strong>Close</strong>
            </button> -->
        </div>
    </PopupDesbloquearEquipo>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import store from "../../../store";
import "@/assets/sass/StylesPlanta.scss";
import { mapState, mapGetters } from "vuex";
import PopupDesbloquearEquipo from "@/components/planta-conte/PopupDesbloquearEquipo.vue";


export default defineComponent({
    name: "newService",
    props: ["terminalSelected"],
    emits: ["close"],
    components: {
        PopupDesbloquearEquipo,
    },
    computed: {
        ...mapState({
            user: (state: any) => state.AUTH.user,
            token: (state: any) => state.AUTH.token,
        }),
        ...mapGetters(["permissions"]),
    },
    data() {
        return {
            desbloquearReason: "",
            messageTraduct: ""
        };
    },
    mounted() { },
    methods: {
        closePopup() {
            this.$emit("close");
        },

        desbloquearEquipo() {
            if (!this.desbloquearReason) {
                store.dispatch("showToast", {
                    message: "Por favor, ingresa el número de serie del kit.",
                    type: "error"
                });
                return;
            }

            const data = {
                kitSerialNumber: this.desbloquearReason // Enviar el número de serie del kit como idDevice
            };

            console.log("Datos que se están enviando a la API:", data); // Para depurar

            store.dispatch("DELETEDEVICE", data)
                .then((response) => {
                    store.dispatch("showToast", {
                        message: "Dispositivo desbloqueado exitosamente.",
                        type: "success"
                    });
                    this.closePopup();
                })
                .catch((error) => {
                    console.error("Error en la respuesta:", error); // Mostrar error en la consola
                    store.dispatch("showToast", {
                        message: "Error al desbloquear el dispositivo. Inténtalo nuevamente.",
                        type: "error"
                    });
                });
        },


    },
});
</script>
<style lang="scss">
.dropdown-content {
    position: fixed;
    display: none;
    width: 160px;
    height: 225px;
    background: #0066ff;
    border-radius: 10px;
    margin-top: 0px;
}

.dropdown-content:last-of-type {
    position: fixed;
    display: none;
    width: 160px;
    height: 225px;
    background: #0066ff;
    border-radius: 10px;
    margin-top: -225px;
    margin-left: 45px;
}

.dropdown-content:hover {
    position: fixed;
    display: none;
    width: 160px;
    height: 225px;
    background: #0066ff;
    border-radius: 10px;
}

.dropdown-content a:hover {
    background-color: #0079ff;
}

.btn-submit-address {
    height: 32px;
    width: 220px;
    border: none;
    border-radius: 9px;
    color: #ffffff;
    font-weight: bold;
    background-color: #0066ff;
}

.btn-submit-mapa {
    height: 32px;
    width: 220px;
    border: none;
    border-radius: 9px;
    color: #ffffff;
    font-weight: bold;
    background-color: #0066ff;
}

.custom-select .vs__dropdown-toggle {
    background-color: white;
}

.custom-input {
    background-color: white;
    border: 1px solid #ccc;
    padding: 8px;
    border-radius: 4px;
    width: 95%;
    cursor: pointer;
}

.header-content {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
}

.poppup-body p {
    color: #ffffff;
}

.btn-content2 button {
    height: 40px;
    cursor: pointer;
    width: 9rem;
    border-radius: 9px;
    background-color: #007bff;
    color: #ffffff;
}

.close-style2 {
    display: flex;
    justify-content: end;
}
</style>