<template>
  <div class="home-content">
    <div class="overlay_2">
      <div class="conten">
        <div v-if="permissions.some((evt: any) => evt.id === 3)" class="service">
          <RouterLink to="/planta">{{ $t("message.home.btn1") }}</RouterLink>
        </div>
        <button v-if="permissions.some((evt: any) => evt.id === 4)" class="service-btn" id="btn-abrir-popup"
          @click="showPopupPrincipal = true">
          <span>{{ $t("message.home.btn2") }}</span>
        </button>
        <div v-if="permissions.some((evt: any) => evt.id === 5)" class="btn-monitoring"
          @click="showPopupPrincipalTest = true">
          <div>{{ $t("message.home.btn3") }}</div>
        </div>

        <button v-if="permissions.some((evt: any) => evt.id === 163)" class="btn-monitoring" @click="showPopupDesbloquearEquipo = true" style="cursor: pointer;">{{ $t("message.home.btn4") }}</button>

        <button v-if="permissions.some((evt: any) => evt.id === 164)" class="btn-monitoring" @click="showPopupAgregarEquipo = true" style="cursor: pointer;">{{ $t("message.home.btn5") }}</button>


        
        <!-- <div
          v-if="permissions.some((evt: any) => evt.id === 5)"
          style="margin-left: 3px !important; cursor: pointer"
          class="monitoring"
          @click="showPopupPrincipalT = true"
        >
          <div>{{ $t("message.home.btn3") }}</div>
        </div> -->

      </div>
    </div>

    <div class="overlay_1">
      <newPlant style="z-index: 5" v-if="showPopup" @close="closePopup()"></newPlant>
      <selectedPrincipal style="z-index: 5" v-if="showPopupPrincipal" @close="closePopup()"
        @confirm="confirmPopupPrincipal()"></selectedPrincipal>
      <div style="z-index: 1" class="mapa"></div>
    </div>

    <selectedPrincipalTest style="z-index: 5" v-if="showPopupPrincipalTest" @close="closePopupTest()"
      @confirm="confirmPopupPrincipalTest()"></selectedPrincipalTest>

    <!-- Desbloquear Equipo Starlink -->
    <DesbloquearEquipo style="z-index: 5" v-if="showPopupDesbloquearEquipo" @close="closePopupTest()">
    </DesbloquearEquipo>

    <!-- Agregar Equipo Starlink -->
    <AgregarEquipo style="z-index: 5" v-if="showPopupAgregarEquipo" @close="closePopupTest()"  @confirm="confirmPopupPrincipal2()"></AgregarEquipo>

  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import newPlant from "../planta/newService.vue";
import selectedPrincipal from "../planta/poppup/selectedPrincipal.vue";
import store from "../../store/index";
import selectedPrincipalTest from "../planta/poppup/selectedPrincipalTest.vue";
// import Planeta from "@/components/planetv2/planeta2.vue";
import { mapState, mapGetters } from "vuex";
import DesbloquearEquipo from "../planta/poppup/desbloquearEquipo.vue";
import AgregarEquipo from "../planta/poppup/agregarEquipo.vue";

export default defineComponent({
  name: "Home",
  components: {
    newPlant,
    selectedPrincipal,
    selectedPrincipalTest,
    DesbloquearEquipo,
    AgregarEquipo
  },
  computed: {
    ...mapState({
      coordenate: (state: any) => state.AUTH.coordenateData,
      user: (state: any) => state.AUTH.user,
      token: (state: any) => state.AUTH.token,
    }),
    ...mapGetters(["permissions"]),
  },
  data() {
    return {
      showPopup: false,
      showPopupPrincipal: false,
      showPopupPrincipalTest: false,
      showPopupDesbloquearEquipo: false,
      showPopupAgregarEquipo: false,
    };
  },
  mounted(){
    // console.log("PERMISOSN GLOBAL", this.permissions)
  },
  methods: {
    closePopup() {
      this.showPopup = false;
      this.showPopupPrincipal = false;
    },
    closePopupTest() {
      this.showPopupPrincipalTest = false;
      this.showPopupDesbloquearEquipo = false;
      this.showPopupAgregarEquipo = false;
    },
    confirmPopupPrincipal() {
      console.log("entro");
      this.showPopupPrincipal = false;
      setTimeout(() => {
        this.showPopup = true;
      }, 1000); // Ajusta el tiempo de espera según sea necesario
    },
    confirmPopupPrincipalTest() {
      console.log("entro");
      this.showPopupPrincipalTest = false;
      setTimeout(() => {
        // this.showPopup = true;
      }, 1000); // Ajusta el tiempo de espera según sea necesario
    },
    confirmPopupPrincipal2() {
      console.log("entro");
      this.showPopupPrincipal = false;
      setTimeout(() => {
        this.showPopup = true;
      }, 1000); // Ajusta el tiempo de espera según sea necesario
    },
  },
});
</script>
<style scoped>
.home-content {
  height: calc(100vh - 107px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.overlay_2 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 0;
  width: 100%;
  opacity: 0.85;
  flex-basis: 35%;
  margin-top: 30px;
}

.overlay_2 .conten {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 939px;
  margin-top: 98px;
}

.overlay_2 .conten .service {
  flex-basis: calc(33.3% - 20px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-left: 15px;
}

.overlay_2 .conten .service-btn {
  flex-basis: calc(33.3% - 20px);
  display: flex;
  justify-content: center;
  cursor: pointer;
  background: rgba(0, 102, 255, 0.25);
  border: 2px solid rgba(0, 102, 255, 0.25);
  border-radius: 10px;
  height: 41px;
  width: 279px;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;

  margin-top: 40px;
  margin-left: 15px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay_2 .conten .service-btn span {
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
}

.overlay_2 .conten .service a {
  margin-top: 40px;
  background: rgba(0, 102, 255, 0.25);
  border: 2px solid rgba(0, 102, 255, 0.25);
  border-radius: 10px;
  height: 41px;
  width: 279px;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay_2 .conten .service a:hover {
  margin-top: 40px;
  background-color: #2001b9fe;
  border: 1px solid #bbbbbb;
  /* border: 2px solid #ffffff; */
  border-radius: 10px;
  font-weight: bold;
  height: 41px;
  width: 279px;
  color: #ffffff;
  /* color: rgba(0, 102, 255, 0.25); */
}

/* ///////////////////////// */
.overlay_1 {
  /* position: absolute; */

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 5;
  flex-basis: 65%;
  /* top: 0; */
  /* opacity: 0.85; */
}

/* -----------mapa animado------------- */

.mapa {
  z-index: 1 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 520px;
  cursor: pointer;
  height: 520px;
  margin-top: 1rem;
  border-radius: 50%;
  background: url(@/assets/12MAP.jpeg);
  background-size: cover;
  box-shadow: inset 0 0 20px #000, inset -40px -10px 20px rgba(3, 32, 56, 0.7),
    inset 5px 5px 0 0 rgba(255, 255, 255, 0.2);

  animation: tierra-girando 44s infinite linear;
}

/* -----------btn monitoring------------- */
.btn-monitoring {
  margin-top: 40px;
  background: rgba(0, 102, 255, 0.25);
  border: 2px solid rgba(0, 102, 255, 0.25);
  border-radius: 10px;
  height: 41px;
  width: 279px;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1.2rem;
}

.btn-monitoring :hover {
  /* margin-top: 40px; */
  background-color: #2001b9fe;
  border: 1px solid #bbbbbb;
  /* border: 2px solid #ffffff; */
  border-radius: 10px;
  font-weight: bold;
  height: 41px;
  width: 279px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* color: rgba(0, 102, 255, 0.25); */
}

/* -----------btn monitoring------------- */

@keyframes tierra-girando {
  0% {
    background-position: 0px center;
  }

  25% {
    background-position: 900px center;
  }

  50% {
    background-position: 1800px center;
  }

  75% {
    background-position: 2700px center;
  }

  100% {
    background-position: 3600px center;
  }
}

/* ------------------------ */
</style>
